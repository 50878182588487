import {isMobile} from 'react-device-detect';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Textarea,
  Button,
} from "@chakra-ui/core"
import React from 'react'



const ContactForm = () => {
  const isSSR = typeof window === 'undefined'
  const [submitted, setSubmitted] = React.useState(false)
  const [isLandscape, setIsLandscape] = React.useState(isSSR ? true : window.matchMedia("(orientation: landscape)").matches)
  const [submitting, setSubmitting] = React.useState(false)
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [message, setMessage] = React.useState('')
  const fn = React.useCallback(() => {
    setIsLandscape(o => !o)
  }, [])
  React.useEffect(() => {
    if (!isSSR) {
      window.addEventListener('orientationchange', fn);
      return () => window.removeEventListener('orientationchange', fn);
    }
  }, [setIsLandscape, fn, isSSR])
  const resetForm = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setMessage('')
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    e.persist()
    setSubmitting(true)
    await fetch('/', { method: 'POST', body: new URLSearchParams(Array.from(e.target.elements)
        .map(i => i.name)
        .reduce((agg, el) => {
          if (!el) return agg
          const value = e.target.elements.namedItem(el).value
          agg[el] = value
          return agg
        }, {}))})
    resetForm()

    setSubmitted(true)
    setSubmitting(false)
  }

  return (
    <Box
      position="absolute"
      background="rgba(0,0,0,0.7)"
      width={{ base: '100%', md: 600 }}
      maxWidth={{ base: '100%', md: 600 }}
      right={{ base: 0, md: '3vh' }}
      bottom={{ base: 0, md: '3vh' }}
      {...isMobile && isLandscape && {
        h: '100vh',
        overflow: 'scroll',
        bottom: 0,
        right: 0,
      }}
    >
      <Flex p={25} direction="column" position="relative">
        <Box flex="none" mb={{ base: 0, md: 4 }}>
          <Text
            mb={4}
            fontSize="1.6rem"
            fontWeight={500}
            color="white"
            lineHeight={1}
            textAlign="center"
          >
            Get in touch!
          </Text>
        </Box>
        <Flex
          flex={1}
          w={{ base: 'full', xl: 550 }}
          m="auto"
          direction="column"
          as="form"
          name="ContactForm"
          data-netlify="true"
          onSubmit={handleSubmit}
          mb={0}
        >
          <input type="hidden" name="form-name" value="ContactForm" />
          <div style={{ display: 'none' }}>
            <label>
              Don’t fill this out if you're human: <input name="bot-field" />
            </label>
          </div>
          <Flex direction={{ base: 'column', sm: 'row' }}>
            <FormControl flex={1} mr={{ sm: 2 }}>
              <FormLabel
                color="white"
                htmlFor="firstName"
                pb={0}
                fontWeight={500}
                fontSize="0.8rem"
              >
                First Name
              </FormLabel>
              <Input
                color="white"
                variant="flushed"
                id="firstName"
                name="firstName"
                value={firstName}
                onChange={({ target: { value } }) => setFirstName(value)}
                focusBorderColor="#454545"
                isRequired
                fontSize="0.9rem"
              />
            </FormControl>
            <FormControl flex={1} ml={{ sm: 2 }}>
              <FormLabel
                color="white"
                htmlFor="lastName"
                pb={0}
                fontWeight={500}
                fontSize="0.8rem"
              >
                Last Name
              </FormLabel>
              <Input
                color="white"
                variant="flushed"
                id="lastName"
                name="lastName"
                value={lastName}
                onChange={({ target: { value } }) => setLastName(value)}
                type="text"
                borderColor="white"
                focusBorderColor="#454545"
                isRequired
                fontSize="0.9rem"
              />
            </FormControl>
          </Flex>
          <FormControl flex={1} mt={{ base: 0, md: 3 }}>
            <FormLabel
              color="white"
              htmlFor="email"
              pb={0}
              fontWeight={500}
              fontSize="0.8rem"
            >
              Email
            </FormLabel>
            <Input
              color="white"
              variant="flushed"
              id="email"
              name="email"
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}
              type="email"
              borderColor="white"
              focusBorderColor="#454545"
              isRequired
              fontSize="0.9rem"
            />
          </FormControl>
          <FormControl flex={1} mt={{ base: 0, md: 6 }}>
            <FormLabel
              color="white"
              htmlFor="message"
              pb={0}
              fontWeight={500}
              fontSize="0.8rem"
            >
              Your Question
            </FormLabel>
            <Textarea
              color="white"
              variant="flushed"
              id="message"
              name="message"
              value={message}
              onChange={({ target: { value } }) => setMessage(value)}
              borderColor="white"
              focusBorderColor="#454545"
              resize="none"
              isRequired
              fontSize="0.9rem"
            />
          </FormControl>
          <Flex  direction="column" alignItems="center">
            <Text
              visibility={submitted ? 'visible' : 'hidden'}
              mb={0}
              justify="center"
              color="white"
              textAlign="center"
              fontSize="0.78rem"
              fontWeight={500}
            >
              Thanks for reaching out! We will get back to you as soon as possible.
            </Text>
            <Button
              mt={2}
              h="auto"
              py={4}
              px={6}
              cursor="pointer"
              borderRadius={30}
              variantColor="red"
              borderColor="#DF020B"
              color="white"
              fontWeight={400}
              opacity={1}
              disabled={!firstName || !lastName || !message || !email || submitting}
              type="submit"
            >
              Submit
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

export default ContactForm
